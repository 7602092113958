.barra-navegacion {
    display: flex;
    flex-direction: row;
    background-color: var(--color-fondo);
    width: 80%;
    height: 76px;
    justify-content: space-around;
}

.seccion-barra {
    width: 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
}

.barra1 {
    justify-content: start;

}

.barra2 {
    justify-content: center;
}

.barra3 {
    justify-content: end;
}

.barraMovil {
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.barraMovilMenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 75px;
    left: 0px;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--color-fondo);
    opacity: 1;
    z-index: 3;
    padding-top: 20px;
    gap: 20px;
}

.hamburger {
    height: 25px;
}

.menu-x {
    height: 25px;
}

.logo {
    height: 40px;
}

.texto-menu {
    font-size: 1.1em;
    color: rgb(255, 255, 255);
    opacity: .8;
    transition: opacity .2s ease-in-out;
}

.texto-menu-small {
    font-size: .8em;
    color: rgb(255, 255, 255);
    margin-left: 5px;
}

.texto-menu:hover, texto-menu-peque:hover {
    opacity: 1;
    cursor: pointer;
}

.imagen-redes-sociales {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    opacity: 1;
    transition: opacity .2s ease-in-out;
}

.logoAAM {
    width: 30px;
    height: 30px;
}

.imagen-redes-sociales:hover {
    opacity: .8;   
}

.contenedor-submenu {
    position: absolute;
    display: none;
}

.con-submenu:hover > .contenedor-submenu {
    display: block;
}

.submenu {
    display: flex;
    flex-direction: column;
    background-color: rgb(3, 0, 50);
    border-radius: 5px;
    padding: 15px 20px;
    margin-top: 20px;
    padding-right: 40px;
    gap: 5px;
    position: relative;
    z-index: 3;
    left: -10px;
}

.opciones-submenu {
    color: var(--color-texto-blanco);
    cursor: pointer;
    margin: 3px 0px;
    font-size: 1.1em;
    font-weight: 600;
}

.opciones-submenu:hover {
    color: var(--color-texto-oscuro);
}

.ocultar {
    display: none;
}


@media (max-width: 768px) {
    .barra-navegacion {
        width: 90%;
        height: 75px;
        justify-content: space-between;
    }

    .barra2, .barra3 {
        display: none
    }

    .barraMovil {
        display: flex;
    }

    .contenedor-submenu {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .submenu {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--color-fondo-menu);
        padding: 15px 10px;
        margin-top: 20px;
        gap: 5px;
        width: 100%;
        position: relative;
    }

    .con-submenu {
        display: flex;
        flex-direction: column;
        width: 90%;
        align-items: center;
    }
    
}