.contenedor-card-sugerencia {
    border-radius: 5px;
    padding: 15px;
    /* box-shadow: 0px 0px 5px rgb(187, 187, 187); */
    display: flex;
    flex-direction: row;
    width: 45%;
    justify-content: flex-start;
    align-items: flex-start;
    background: none;
    color: black;
}

.contenedor-card-sugerencia--img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

@media (max-width: 768px) {

    .contenedor-card-sugerencia {
        width: 95%;
        margin-bottom: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}