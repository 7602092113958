.seccion-titulo-guias {
    width: 90%;
    background-color: var(--color-fondo);
    padding-bottom: 40px;
}

.seccion-titulo-guias > h1 {
    margin-top: 70px;
    color: var(--color-texto-blanco);
    font-size: 3em;
}

.seccion-titulo-guias--texto > p {
    font-size: 1.2em;
    color: var(--color-texto-claro-titulo);
    line-height: 1.7;
    margin-top:10px;
    text-align: justify;
}

.imagen-titulo-guia {
    max-width: 100%;
    height: 80%;
    object-fit: cover;
    border-radius: 5px;
}

.separador-guias {
    width: 100%;
    box-shadow: 0px 0px 5px rgb(121, 121, 121);
    background-color: rgb(183, 183, 183);
    height: .5px;
    margin: 40px 0px;
}

.seccion-zonas-escalada-guias {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--color-fondo-claro);
    
}

.imagen-mapa {
    margin-top: 30px;
    width: 95%;
}



.seccion-card-zonas {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 23%));
    justify-content: center;
    padding-top: 30px;
    gap: 20px;
}

@media (max-width: 768px) {

    .seccion-titulo-guias {
        flex-direction: column;
        width: 90%;
        padding-bottom: 20px;
        align-items: center;
    }

    .seccion-titulo-guias > p {
        font-size: 1.1em;
        width: 100%;
        text-align: justify;
    }

    .seccion-titulo-guias > h1 {
        align-self: flex-start;
    }
    
    .seccion-alerta-guias {
        width: 98%;
    }
    
    .seccion-alerta-contenido-guias {
        width: 90%;
        align-items: flex-start;
    }

    .seccion-alerta-contenido-guias {
        text-align: justify;
    }
 
    .seccion-alerta-card-informacion {
        flex-direction: column;
        gap: 0px;
        width: 90%;
        margin: 20px 0px;
    }
    
    .contenedor-alerta-card-informacion {
        gap: 10px;
        width: 100%;
        padding-top: 30px;
    }

    .contenedor-alerta-card-informacion > p {
        margin: 0px 0px;
    }

    .separador-guias {
        display: none;    }
    
    .seccion-zonas-escalada-guias {
        width: 100%;
    }
    
    .seccion-zonas-escalada-guias > img {
        width: 100%;
    }
    
    .seccion-card-zonas {
        width: 95%;
        grid-template-columns: repeat(auto-fill, minmax(95%, 95%));
        gap: 10px;
    }

    .imagen-titulo-guia {
        width: 90%;
        margin-top: 20px;
    }

}
