.contenedor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--color-fondo);
  padding: 0px 0px;
}

.cabecera {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--color-fondo)
}

.contenedor-main {
  display: flex;
  flex-direction: column;
}
