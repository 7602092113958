.seccion-superior {
    display: flex;
    flex-direction: row;
    width: 90%;
    background-color: var(--color-fondo);
/*     margin-top: 25px; */
    padding: 30px 0px;
}

.contenedor-mitad {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    flex-wrap: wrap;
    text-align: left;
    color: var(--color-texto-blanco);
}

.contenedor-mitad h1 {
    font-size: 3em;
    line-height: 1.2em;
}

.contenedor-mitad p {
    margin: 15px 0px;
    color: var(--color-texto-claro);
    font-size: 1.4em;
    line-height: 1.5em;
    width: 95%;
    text-align: justify;
}

.contenedor-mitad-foto {
    width: 90%;
    object-fit: cover;
    border-radius: 5px;
}


.seccion-medio {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
}

.seccion-logros {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 60px;
    background-color: rgb(255, 255, 255);
}

.seccion-logros > p {
    color: var(--color-texto-claro);
}

.seccion-objetos-logros {
    display: flex;
    flex-direction: row;
    padding-top: 50px;
    width: 95%;
    justify-content: space-around;
    align-items: center;
}

.seccion-alerta-card-informacion {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 85%;
    justify-content: space-around;
    margin: 0px 0px;
    flex-wrap: wrap;
    border: solid 1px rgba(0,0,0, .500);
    border-radius: 5px;
    padding-top: 10px;
}

.contenedor-alerta-card-informacion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 32%;
    padding-top: 30px;
}

.contenedor-alerta-card-informacion > img {
    width: 50px;
    height: 50px;
}

.contenedor-alerta-card-informacion > p {
    font-size: 1.2em;
    line-height: 1.7;
    color: var(--color-texto-oscuro);
    margin: 20px 0px;
}

.seccion-sugerencias-guias {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
    padding-top: 40px;
    align-items: center;
}

.seccion-sugerencias-guias > h1 {
    font-size: 2em;
    margin-bottom: 15px;
    margin-left: 10px;
}

.seccion-alerta-guias {
    display: flex;
    flex-direction: column;
    width: 90%;
    background-color: white;
    align-items: center;
    padding: 30px 0px;
    border-radius: 5px;
    border: none;
/*     margin-bottom: 25px; */

}

.seccion-alerta-foto {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.seccion-alerta-contenido-guias {
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: flex-start;
    margin-bottom: 20px;
}

.seccion-alerta-contenido-guias-titulo {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.seccion-alerta-contenido-guias > h1 {
/*     margin-top: 20px; */
    font-size: 2.5em;
}

.seccion-alerta-contenido-guias-titulo > p > span {
    font-size: 1.1em;
    font-weight: bold;
    color: black
}

.seccion-alerta-contenido-guias-titulo > p {
    font-size: 1.2em;
    line-height: 1.7;
    color: var(--color-texto-oscuro);}

.seccion-alerta-contenido-guias > p {
    font-size: 1.2em;
    line-height: 1.7;
    color: var(--color-texto-oscuro);
/*     margin: 20px 0px; */
}

.contenedor-principal-fotos {
    width: 90%;
    margin-top: 20px;
}

.seccion-principal-imagen {
    object-fit: contain;
    width: 100%;
    border-radius: 5px;
}

.link-alerta-guias {
    margin-bottom: 20px;
}

.bg-green {
    background-color: white;
}

.bg-light-blue {
    background-color: white;
}

.seccion-objetos-logros > p {
    color: var(--color-texto-claro);
}

.seccion-noticias {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    gap: 10px;
}

.seccion-card-noticias {
    display: grid;
    grid-template-columns: repeat(3, minmax(350px, 1fr));
    gap: 20px;
    justify-items: center;
    justify-content: center;
    align-self: center;
    margin: 30px 0px;
    width: 95%;
}

@media (max-width: 768px) {
    .seccion-superior {
        flex-direction: column-reverse;
        width: 100%;
        margin-top: 15px;
        padding: 20px 0px;
    }

    .contenedor-mitad {
        align-self: center;
        align-items: center;
        width: 95%;
        text-align: justify;
    }

    .contenedor-mitad h1 {
        font-size: 3em;
        line-height: 1.2em;
        align-self: flex-start;
        text-align: start;

    }
    
    .contenedor-mitad p {
        font-size: 1.3em;
        line-height: 1.5em;
    }
     
    .boton {
        align-self: flex-start;
        margin: 10px 0px;
    }

    .seccion-card-noticias {
        grid-template-columns: repeat(1, minmax(350px, 1fr));
        width: 95%;
    }

    .contenedor-principal-fotos {
        width: 95%;
        margin-top: 20px;
    }
}