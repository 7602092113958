.boton {
    height: 40px;
    border-radius: 5px;
    padding: 10px 25px;
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity .2s ease-in-out;
    margin-right: 10px;
}

.boton:hover {
    opacity: .8;
}

.texto-boton {
    color: white;
    font-size: 1.1em;
    font-weight: 600;
}

.logotipo {
    height: 30px;
}

.verde {
    background-color: rgb(0, 99, 0);
}

.celeste {
    background-color: rgb(39, 165, 211);
}

.gris {
    background-color: rgb(51, 51, 51);
}