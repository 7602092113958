.contenedor-card-zona {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: solid 1px rgba(0, 0, 0, 0.200);
    box-shadow: 10x 10px 20px rgba(0, 0, 0, 0.425);
    padding-bottom: 20px;
}

.contenedor-card-zona > img {
    width: 100%;
    height: 350px;
}

.contenedor-card-zona > p, .contenedor-h3-link {
    width: 90%;
    align-self: center;
    font-size: 1.3em;
    line-height: 1.5;
}

.contenedor-card-zona > p {
    color: var(--color-texto-oscuro)
}

.h3-link {
    color: var(--color-fondo);
    margin-bottom: 20px;
    margin-top: 20px;
}

.h3-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {

    .contenedor-card-zona > p  {
        font-size: 1em;
    }

    .contenedor-card-zona   {
        width: 90%;
        margin-bottom: 20px;
        justify-self: center;
    }

    .contenedor-card-zona > img {
        width: 100%;
        height: 450px;
    }

    .h3-link {
        font-size: 1em;
    }
    
    .seccion-card-zonas {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
