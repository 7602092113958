.seccion-titulo-proyecto {
    display: flex;
    flex-direction: column;
    width: 70%;
    background-color: var(--color-fondo);
    height: 150px;
    
}

.seccion-titulo-proyecto > h1 {
    margin-top: 70px;
    color: white;
    font-size: 3em;
}

.contenedor-contenido-proyecto {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100;
    background-color: white;
}

.contenido-proyecto {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    background-color: white;
    padding: 30px 30px;
    border-radius: 5px;
}

.contenedor-texto-proyecto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.texto-foto-proyecto {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.texto-foto-proyecto > div > p {
    font-size: 1.2em;
    line-height: 1.7;
    text-align: justify;
    margin-bottom: 15px;
}

.imagen-proyecto {
    width: 30%;
    float: right;
    margin: 20px;
}

.contenedor-imagen-pie-proyecto {
    width: 90%;
}

.imagen-pie-proyecto {
    object-fit: contain;
    width: 100%;
}

.texto-pie-proyecto {
    margin-top: 10px;
    font-size: 1.2em;
    line-height: 1.7;
    text-align: justify;
    color: var(--color-texto-claro)
}

.separador-proyecto {
    width: 100%;
    box-shadow: 0px 0px 5px black;
    background-color: var(--color-texto-claro);
    height: .5px;
    margin: 40px 0px;
}

.texto-creadores-proyecto {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: flex-start;

}

.texto-creadores-proyecto > p {
    font-size: 1.2em;
    line-height: 1.7;
    text-align: justify;
    color: var(--color-texto-oscuro)
}

.texto-creadores-proyecto > h2 {
    font-size: 1.8em;
    margin-top: 20px;
}


@media (max-width: 768px) {
    .seccion-titulo-proyecto {
        width: 95%;
        height: 150px;
        justify-content: center;
    }
    
    .seccion-titulo-proyecto > h1 {
        margin-top: 0px;
        font-size: 2.5em;
    }

    .contenido-proyecto {
        width: 100%;
        top: 0px;
    }

    .contenedor-texto-proyecto {
        width: 100%;
    }

}