@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --color-fondo-footer: rgb(7,55,99);
    --color-fondo-menu: rgb(7,55,99);
    --color-fondo-submenu: rgba(1, 26, 49, .400);
    --color-fondo: rgb(7,55,99);
    --color-fondo-claro: rgb(245, 245, 245);
    --color-fondo-blanco: rgb(255, 255, 255);
    --color-texto-blanco: rgb(255,255,255);
    --color-texto-claro: rgb(236, 236, 236);
    --color-texto-oscuro: rgb(64, 64, 64);
    --color-texto-claro-titulo: rgb(176, 176, 176);
    --ancho-interno: 90%;
}

* {
    margin: 0;
    padding: 0;
    font-family: Inter, sans-serif;
  }

.cabecera {
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 2;
}

main {
    margin-top: 75px;
}

footer {
    width: 100%;
    background-color: var(--color-fondo-footer);
    display: flex;
    flex-direction: row;
    justify-content: center;
}
