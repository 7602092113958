.seccion-titulo-sector {
    display: flex;
    flex-direction: column;
    width: 55%;
    background-color: var(--color-fondo);
    align-items: center;
    padding-top: 50px;
    padding-bottom: 30px;
}

.texto-link-sector {
    color: rgb(40,181,231);
    font-size: 1em;
    align-self: flex-start;
    padding: 20px 0px;
    text-decoration: underline;
}

.seccion-titulo-sector > h1 {
    color: white;
    font-size: 3em;
    align-self: flex-start;
}

.seccion-titulo-sector > p {
    font-size: 1.2em;
    color: var(--color-texto-claro-titulo);
    line-height: 1.7;
    margin-top:10px;
    align-self: flex-start;
}

.seccion-vias-sector {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: center;
    gap: 30px;
}

.seccion-imagenes-vias-sector-imagen {
    width: 90%;
    object-fit: contain;
}

.seccion-imagenes-vias-sector {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10%, 50%));
    align-items: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
}

.seccion-listado-vias-sector {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px 0px;
}

.contenedor-seccion-acceso-sector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--color-fondo-blanco);
    width: 100%;
}


.seccion-acceso-sector {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: var(--ancho-interno);
    background-color: var(--color-fondo-blanco);
    padding: 30px 0px;
}

.seccion-acceso-sector > img {
    width: 50%;
}

.seccion-texto-acceso-sector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.seccion-texto-acceso-sector > p {
    font-size: 1.2em;
    line-height: 1.7;
    color: var(--color-texto-oscuro);
}

.seccion-sectores-sectores {
    display: flex;
    flex-direction: column;
    width: 90%;
    background-color: var(--color-fondo-claro);
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.seccion-card-sectores-sectores-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top:30px;
    margin-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: pink;
    width: 100%;
}

.seccion-card-sectores-sectores {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 23%));
    justify-content: center;
    padding-top: 30px;
    gap: 20px;
}

@media (max-width: 768px) {
    .seccion-titulo-sector {
        width: 95%;
    }

    .seccion-vias-sector {
        margin-bottom: 15px;
    }

    .seccion-vias-sector > img {
        width: 95%;
        margin-bottom: 0px;
    }

    .seccion-listado-vias-sector {
        width: 95%;
        padding: 0px;
    }

    .seccion-acceso-sector {
        flex-direction: column;
    }

    .seccion-acceso-sector > img {
        width: 100%;
    }

    .seccion-texto-acceso-sector > p {
        font-size: 1em;
    }

    .seccion-card-sectores-sectores {
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(95%, 95%));
    }
}
